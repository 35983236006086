import Convince from './convince';
import Login from '../components/login/Login.vue';
import 'nodelist-foreach-polyfill';
import MessageHandler from "./message-handler"
import Vue from 'vue';

(function(){
  window.Convince = Convince;
})();

(function(){
  function ready() {
    let metas = document.querySelectorAll('meta')
    if (document.querySelector('meta[name="csrf-token"]')){
      Vue.prototype.$csrfToken = document.querySelector('meta[name="csrf-token"]').content
    }
    initLogin();
    initMessaging();
  }

  function initVueComponent (selector, component, props) {
    var els = document.querySelectorAll(selector)
    els.forEach(function (el) {
      var app = new Vue({
        el,
        render: h => h(component,
          {props: props}
        )
      })
    })
  }

  function initLogin() {
    initVueComponent('#login-app', Login, {})
  }

  function initMessaging() {
    let els = document.querySelectorAll('.messaging-enabled');
    els.forEach((el)=>{
      let id = el.dataset.id
      let messageChannel = new MessageHandler(id, updateMessages)
    })
  }

  function updateMessages(data){
    let message = data.message
    console.log(message)
    let els = document.querySelectorAll('.messaging-enabled');
    els.forEach((el)=>{
      if (message.status === 'ok') {
        el.innerHTML = "<p><a href='" + message.downloadURL + "'>Download</a></p>"
      } else {
        el.innerHTML = "<p>Something went wrong</p>"
      }
    })
  }

  document.addEventListener('DOMContentLoaded', ready)
})()

