import consumer from "./consumer"

class ResponsesChannel {
  constructor () {

  }
  connected() {
    // ON CONNECT
    this.appear()
  }

  disconnected() {
    // ON DISCONNECT
  }

  appear () {
    this.send('appear', {id: this.id})
  }

  disappear() {
    this.send('disappear', {id: this.id})
  }

  received(data) {
    console.log(data)
  }

  ping (data) {
    this.send('ping')
  }

  create (id) {
    this.id = id
    this.channel = consumer.subscriptions.create({ channel: "ResponsesChannel", campaign_id: id }, {
      connected: this.connected.bind(this),
      received: this.received.bind(this),
      disconnected: this.disconnected.bind(this),
      ping: this.ping.bind(this)
    })
  }
  send(action, data) {
    this.channel.perform(action, data)
  }
}

export default ResponsesChannel
