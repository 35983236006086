import consumer from "./consumer"

class MessageChannel {
  constructor () {
  }

  create (id, listeners) {
    this.id = id
    this.channel = consumer.subscriptions.create({ channel: "MessageChannel", id: id }, listeners)
  }
  send(action, data) {
    this.channel.perform(action, data)
  }
}

export default MessageChannel