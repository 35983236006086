import ResponsesChannel from "../../../channels/responses_channel";
import axios from "axios";

function Convince(config) {
  this.config = config;
  this.csrfToken = null;
  this.channel = new ResponsesChannel();
  this.channel.create(config.campaignId);
  setInterval(
    function() {
      this.channel.ping();
    }.bind(this),
    5000
  );
}

Convince.prototype = {
  apiUrl: function(path) {
    return convinceConfig.base + path;
  },
  notifyChannel: function(tokenId) {},
  start: function() {
    var csrfToken = this.getCSRFToken();
    var promise = axios({
      method: "post",
      url: this.apiUrl("/start/" + this.config.campaignId),
      headers: { "X-CSRF-Token": csrfToken },
      data: {
        url: location.href,
        account: this.config.account,
      },
    });
    promise
      .then(
        function(resp) {
          this.config = resp.data;
        }.bind(this)
      )
      .catch(this.onError.bind(this));
    return promise;
  },
  event: function(data) {
    var csrfToken = this.getCSRFToken();
    var payload = {
      uuid: this.config.responseId,
      source_type: "response",
    };
    if (!this.config.responseId || data.event == "open_campaign") {
      payload.source_type = "campaign";
    }
    Object.assign(payload, data);
    return axios({
      method: "post",
      url: this.apiUrl("/leads/" + this.config.campaignId + ".js"),
      headers: { "X-CSRF-Token": csrfToken },
      data: payload,
    });
  },
  update: function(data) {
    var payload = { data: data, method: "_put", token: this.config.tokenId };
    var csrfToken = this.getCSRFToken();
    return axios({
      method: "put",
      url: this.apiUrl(this.config.respondTo),
      data: payload,
      headers: { "X-CSRF-Token": csrfToken },
    }).catch(this.onError.bind(this));
  },
  finalise: function() {
    var payload = {
      data: { complete: true },
      method: "_post",
      token: this.config.tokenId,
    };
    var csrfToken = this.getCSRFToken();
    return axios({
      method: "post",
      url: this.apiUrl(this.config.respondTo + "/finalise"),
      data: payload,
      headers: { "X-CSRF-Token": csrfToken },
    }).catch(this.onError.bind(this));
  },
  result: function(options) {
    var payload = { method: "_post", token: this.config.tokenId };
    if (typeof options !== "undefined") {
      payload.options = options;
    }
    return axios({
      method: "post",
      url: this.apiUrl(this.config.respondTo + "/result"),
      data: payload,
      headers: { "X-CSRF-Token": this.getCSRFToken() },
    }).catch(this.onError.bind(this));
  },
  response: function(uuid) {
    return axios({
      method: "get",
      url: this.apiUrl(`/responses/${uuid}.js`),
    }).catch(this.onError.bind(this));
  },
  scores: function(options) {
    var payload = { method: "_post", token: this.config.tokenId };
    if (typeof options !== "undefined") {
      payload.options = options;
    }
    let url = this.apiUrl(this.config.respondTo + "/scores");
    if (typeof options.responseUUID !== "undefined") {
      url = this.apiUrl(`/responses/${options.responseUUID}/scores`);
    }
    return axios({
      method: "post",
      url: url,
      data: payload,
      headers: { "X-CSRF-Token": this.getCSRFToken() },
    }).catch(this.onError.bind(this));
  },
  agreement: function(key) {
    var payload = { key: key, method: "_post", token: this.config.tokenId };
    return axios({
      method: "post",
      url: this.apiUrl(this.config.respondTo + "/agreement"),
      data: payload,
      headers: { "X-CSRF-Token": this.getCSRFToken() },
    }).catch(this.onError.bind(this));
  },
  polling: function(key) {
    var payload = { key: key, method: "_post", token: this.config.tokenId };
    return axios({
      method: "post",
      url: this.apiUrl(this.config.respondTo + "/poll"),
      data: payload,
      headers: { "X-CSRF-Token": this.getCSRFToken() },
    }).catch(this.onError.bind(this));
  },
  ranking: function(key) {
    var payload = { key: key, method: "_post", token: this.config.tokenId };
    var csrfToken = this.getCSRFToken();
    return axios({
      method: "post",
      url: this.apiUrl(this.config.respondTo + "/ranking"),
      data: payload,
      headers: { "X-CSRF-Token": csrfToken },
    }).catch(this.onError.bind(this));
  },
  breakdown: function(key) {
    var payload = { key: key, method: "_post", token: this.config.tokenId };
    var csrfToken = this.getCSRFToken();
    return axios({
      method: "post",
      url: this.apiUrl(this.config.respondTo + "/breakdown"),
      data: payload,
      headers: { "X-CSRF-Token": csrfToken },
    }).catch(this.onError.bind(this));
  },
  promoteUser: function(fields) {
    var data = { fields: fields };
    data.token = this.config.tokenId;
    var csrfToken = this.getCSRFToken();
    return axios({
      method: "post",
      url: this.apiUrl(this.config.respondTo + "/promote_user"),
      data: data,
      headers: { "X-CSRF-Token": csrfToken },
    }).catch(this.onError.bind(this));
  },
  onError: function(e) {
    if (
      typeof this.config.errorCallback != "undefined" &&
      this.config.errorCallback
    ) {
      this.errorCallback(e);
    }
    if (this.config.debug === true) {
      console.log(e);
    }
  },
  getCSRFToken: function() {
    if (!this.csrfToken) {
      this.csrfToken = document
        .querySelectorAll("meta[name='csrf-token']")[0]
        .getAttribute("content");
    }
    return this.csrfToken;
  },
  fieldDefault: function(value, defaultValue) {
    return typeof value == "undefined" ? defaultValue : value.toString();
  },
};

export default Convince;
