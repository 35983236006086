<template>
  <section class='pod'>
    <header>
      <h3>Sign in</h3>
    </header>
    <div class="content">
        <div class='errors' v-if='errors'>
          <p>{{errors}}</p>
        </div>

        <form ref='form' method='post' @submit.prevent action='/users/sign_in' class="form">
          <input type="hidden" name="authenticity_token" value="$csrfToken">
          <transition name='fade-and-slide'>
            <div class='steps' v-if='!requesting'>
              <div class='step-1' v-show='step === 1' key='step-1'>
                <div class="field">
                  <label for="user[email]">Email address</label>
                  <input v-model='email' autocomplete='username' name='user[email]' type="email">
                </div>
                <div class="field">
                  <label for="user[password]">Password</label>
                  <input v-model='password' autocomplete='current-password' name='user[password]' type="password">
                </div>
                <div class="field">
                  <input name='user[remember_me]' type='checkbox'>
                  <label for='user[remember_me]'>Remember me</label>
                </div>
                <div class="actions" v-if='step === 1' key='step-1-actions'>
                  <input @click.once='requestCode' type='submit' value='Sign in'>
                </div>
                <div class="links">
                  <a href="/users/sign_up">Register</a><br>
                  <a href="/users/password/new">Forgot your password?</a><br>
                  <a href="/users/confirmation/new">Didn't receive confirmation instructions?</a>
                </div>
              </div>

              <div class='step-2' key='step-2' v-show='step === 2'>
                <div class="field">
                  <label for="user[otp_attempt]">SMS code</label>
                  <input v-on.keyup.enter.prevent name='user[otp_attempt]' type="text">
                </div>
                <div class="field">
                  <p v-if='attempts < 2'>Didn't receive a code? <a href='#' @click.prevent.once='requestCode'>Request another code</a>.</p>
                  <p v-if='attempts >= 2'>Still no code? <a href='mailto:support@cogniclick.com'>Contact support</a>.</p>
                </div>
                <div class="actions" v-if='step === 2' key='step-2-actions'>
                  <input @click='signIn' type='submit' value='Submit'>
                </div>
              </div>
            </div>
            <div v-if='requesting'>
              <div class="loader">Loading...</div>
            </div>
          </transition>
        </form>

    </div>
  </section>
</template>
<script>
import SaveMixin from '../mixins/SaveMixin'
export default {
  created () {

  },
  mixins: [SaveMixin],
  data () {
    return {
      step: 1,
      email: '',
      password: '',
      otp: '',
      errors: null,
      attempts: 0,
      requesting: false
    }
  },
  methods: {
    requestCode () {
      this.errors = null
      this.requesting = true
      this.attempts += 1
      this.post('/users/request_sms.json', {
        user: {
          email: this.email,
          password: this.password
        }
      }).then((resp)=>{
        if (resp.data.result === 'sent') {
          this.requesting = false
          this.step = 2
          return
        }
        if (resp.data.redirect) {
         window.location.href = resp.data.redirect
        }
        if (resp.data.result === 'error') {
          this.requesting = false
          this.errors = 'Authentication error'
        }
      })
    },
    signIn () {
      this.$refs.form.submit()
    }
  }
}
</script>
<style lang='scss'>
  .errors {
    margin-bottom: 1em;
    p {
      margin: 0;
      color: red;
    }
  }

.fade-and-slide-enter-active, .fade-and-slide-leave-active {
  transition: 0.5s all;
}
.fade-and-slide-leave-active {

}
.fade-and-slide-enter, .fade-and-slide-leave-to {
  opacity: 0;
}
.fade-and-slide-leave-to {

}
</style>