import axios from 'axios';
export default {
  methods: {
    get (url) {
      return axios.get(
        url,
        {headers: this.postHeaders()}
      )
    },
    post (url, formData) {
      return axios.post(
        url,
        formData,
        {headers: this.postHeaders()}
      )
    },
    put (url, formData) {
      return axios.put(
        url,
        formData,
        {headers: this.postHeaders()}
      )
    },
    delete (url) {
      return axios.delete(
        url, {headers: this.postHeaders()}
      )
    },
    getCSRF () {
      return document.querySelector('meta[name="csrf-token"]').content;
    },
    postHeaders () {
      return {
        'X-CSRF-Token': this.getCSRF()
      }
    }
  }
}