import MessageChannel from "../../../channels/message_channel";

export default class MessageHandler {
  constructor(id, onUpdate) {
    this.id = id;
    this.onUpdate = onUpdate;
    this.channel = new MessageChannel();
    this.channel.create(id, {
      connected: this.connected,
      received: this.received.bind(this),
      disconnected: this.disconnected.bind(this),
    });
  }
  connected() {}

  received(data) {
    this.onUpdate(data);
  }

  disconnected() {
    console.log("Disconnected from message handler");
  }
}
